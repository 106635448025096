
import './navbarhome.css';

import { Link } from "gatsby"

import React, { useState,useEffect } from 'react';

import {login, getProfilePicUrl, getUserName, isUserSignedIn} from './firebase_login';

import useFirebase from './useFirebase';


import {connect} from 'react-redux';

import { increment, update_x, edit_boxes, pop_boxes, invalidate, selectedText, render_x, decrement, reset } from "../actions/sampleAction";

function Navhome(props) {

    const firebase = useFirebase();
    
    useEffect(() => {
      // const origin_ = window.location.origin

      // document.getElementById('href4').href = window.location.origin

      document.getElementById('login').addEventListener('click', (e) => {

        if (!firebase) return; 
        e.preventDefault();
        e.stopPropagation();

        // login(e,props, firebase)
        document.getElementById('signx').style.display = 'block'
}
);

}, [firebase]) //useEffect

	return (
		<div>


		<nav className="navbar navbar-inverse navbar-fixed-top home-fixed-top" role="navigation">
    

        <div className="navbar-header">
            
            <div className="navbar-brand">
                <Link className="CB" to="/">TurtleClip</Link> 
            </div>

            <div id="google_translate_element"></div>

          
            <div className='home-list'>
              <Link to="/pricing">PRICING</Link>
              <a id="login" className="signup">LOG IN</a>
            </div>
        </div>

</nav>


		</div>

		)
}


const mapStateToProps = (state) => {
  console.log('!!!mapStateToProps', state)
  return {
  boxes2: state.data.boxes2,
  canvasValid: state.data.canvasValid,
  selectText: state.data.selectText,

  xout: state.data.xout,
  width: state.data.width,
  height: state.data.height,
  pc: state.data.pc,
  back_bar: state.data.back_bar, 
  front_bar: state.data.front_bar,
  bar_height: state.data.bar_height,
  duration: state.data.duration,
  dvid: state.data.dvid,
  pre_sub: state.data.pre_sub,
  ti: state.data.ti,

  render: state.data.render,
  subscribe: state.data.subscribe,
  uid: state.data.uid,
  email: state.data.email,
  pic: state.data.pic,
  filename: state.data.filename,
  
}}




Navhome.displayName = "Navhome";
export default connect(mapStateToProps, { increment, update_x, edit_boxes, pop_boxes, invalidate, selectedText, render_x, decrement, reset })(React.memo(Navhome));
