import React, {useEffect} from 'react';
import './imagecomparison.scss';
// import original from './img/original.png';
// import modified from './img/modified.png';

// import {beforeAfter} from './before-after';
/* global $ */

function moveDivisor() {

  var divisor = document.getElementById("divisor"),
    handle = document.getElementById("handle"),
    slider = document.getElementById("xslider");

    slider.value = parseFloat(slider.value)

    console.log('slider', slider.value)
  handle.style.left = slider.value+"%";
  divisor.style.width = slider.value+"%";
}


function Imagecomparison(props){

 
useEffect(() => {
  try {
  // window.onload = function() {

  if (document.getElementById("xslider")) moveDivisor();

	var slider = document.getElementById("xslider");
	slider.addEventListener("input", function(e){
	moveDivisor();
  })
  // };

} catch {}

},[])



		// $('.ba-slider').beforeAfter();

return (

<div className="split2 xcontainer">
  <div id="comparison">
    <figure>
      <div id="handle"></div>
      <div id="divisor"></div>
    </figure>
    <input type="range" min="0" max="100" value="50" id="xslider" onInput="moveDivisor()" />
  </div>
 </div>
)}

Imagecomparison.displayName = "Imagecomparison";
export default React.memo(Imagecomparison);