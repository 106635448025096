import React from "react"
import { Link } from "gatsby"
import { graphql } from "gatsby"

import '../components/App.css';
import '../components/index.css';


import Layout from "../components/layout"
// import Image from "../components/image"
import SEO from "../components/seo"
import Home from "../components/home"

// import Header from "../components/header"

import { Helmet } from "react-helmet"

// export default () => {

class BlogIndex extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const description = data.site.siteMetadata.description
    // const posts = data.allMarkdownRemark.edges

  return (
  	<div>

    <Layout>
      <Home />

      <SEO description={description} 
	  keywords={[`subtitles`, `transcribe`, `text`, `video`]}
	  type="website"
      lang='en' title={siteTitle} />

    </Layout>


  </div>
  )
}
}


export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title,
        siteUrl,
        description
      }
    }

  }
  `